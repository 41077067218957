/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@4.1.3/dist/css/bootstrap.min.css
 * - /gh/posabsolute/jQuery-Validation-Engine@2.6.4/css/validationEngine.jquery.min.css
 * - /npm/flatpickr_plus@1.1.20/dist/flatpickr.min.css
 * - /npm/flatpickr_plus@1.1.20/dist/plugins/yearDropdown/style.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
